<template lang="pug">
#app
  v-container(grid-list-md, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar('questionario')", data-vv-name="questionario")
          v-card.elevation-1
            v-card-text
              v-layout(row, wrap)
                v-layout(row,wrap, v-if="questionario_id")
                  v-flex.md10.xs12
                    v-text-field(label="Link do diagnóstico rápido", color="primary", id="linkDiagnosticoRapido" v-model='linkDiagnosticoRapido')
                  v-flex.md2.xs12
                    v-btn(@click='copyTestingCode' color="bt-primary").text.xs.center
                        v-icon.mr-1.white--text content_copy
                        span.white--text Copiar link

                v-flex.md12.xs12
                  v-text-field(label="Nome", color="primary", required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", data-vv-as="Nome", type="text", v-model='questionario.nome')
                v-flex.md6.xs6
                  v-checkbox(label="Moeda", color="primary", data-vv-name="moeda", data-vv-as="Moeda",  v-model='questionario.moeda')
                v-flex.md6.xs6
                  v-text-field(label="Fator", color="primary", required, v-validate="'required'", :error-messages="errors.collect('fator')", data-vv-name="fator", data-vv-as="Fator", type="number", v-model='questionario.fator')
                v-flex.md12.xs12.pa-2
                  span Os campos pré-definidos no texto abaixo, são utilizados para exibir a razão social da empresa e o valor do passivo. Desta forma, se não houver necessidade de utilizá-los, basta retirá-lo do texto abaixo.
                  br
                  span Campos disponíveis para uso:
                  span.font-weight-bold {nomeQuestionario} {razaoSocial} {valorPassivo}
                  br
                  span.red--text.font-weight-bold Atentar-se a escrita dos campos e também as chaves que são de uso obrigatório.
                v-flex.md12.xs12
                  tinymce#d1(v-model='questionario.texto_email', :other_options="options")
                v-flex.md6.xs6
                  v-textarea(label="Conteúdo", color="primary", required, v-validate="'required'", :error-messages="errors.collect('conteudo_email')", data-vv-name="conteudo_email", data-vv-as="Conteúdo", type="text", v-model='questionario.conteudo_email')
                v-flex.md6.xs6
                  v-textarea(label="Apendice", color="primary", required, v-validate="'required'", :error-messages="errors.collect('apendice_email')", data-vv-name="apendice_email", data-vv-as="Apêndice", type="text", v-model='questionario.apendice_email')

              v-layout(row, wrap)
                v-flex.xs12.text-xs-right
                  v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

      v-flex(v-if="questionario_id").xs12.mt-2
        v-card.elevation-1
          v-card-text
            v-layout(row, wrap)
              v-flex.xs12
                v-layout(row, wrap)
                  span.title.mt-3.ml-3 Questões
                  v-spacer
                  //- v-autocomplete(label="Filtrar por categoria", :items="listCategorias", :disabled="!listCategorias.length", item-text="texto", item-value="id", color="primary", v-model="filter.categoria_questao_id", :loading="loadingCategorias", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
                  v-spacer
                  v-btn(color="success", @click.native="openDialogQuestao(null)").mt-3.white--text Cadastrar Questão

      v-flex(v-if="questionario_id").xs12.mt-2
        v-card.elevation-1
          v-card-text
            v-layout(row, wrap)
              v-layout(row, wrap).mt-4
                v-flex.xs12.pa-2
                  v-data-table(:headers='headers', :items='listQuestoes', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", item-key="$index")
                    template(slot='items', slot-scope='props').elevation-0
                      td(width="5%").text-xs-center {{ props.item.id }}
                      td.text-xs-left {{ props.item.texto }}
                      td(width="15%").text-xs-center
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small,  @click="openDialogQuestao(props.item)")
                            v-icon edit
                          span Editar
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color="red", small, @click="deleteQuestao(props.item)")
                            v-icon delete_forever
                          span Excluir
      v-flex(v-if="questionario_id").xs12.mt-2
        v-card.elevation-1
          v-card-text
            v-layout(row, wrap)
              v-flex.xs12
                v-layout(row, wrap)
                  span.title.mt-3.ml-3.mb-3 Resultados
                  v-spacer
                  v-spacer

      v-flex(v-if="questionario_id").xs12.mt-2
        v-card.elevation-1
          v-card-text
            v-layout(row, wrap)
              v-layout(row, wrap).mt-4
                v-flex.xs12.pa-2
                  v-data-table(:headers='headersResultados', :items='resultados.length ? listResultados : []', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", item-key="$index")
                    template(slot='items', slot-scope='props').elevation-0
                      td(width="5%").text-xs-center {{ props.item.id }}
                      td.text-xs-left {{ props.item.nome_responsavel }}
                      td.text-xs-left {{ props.item.razao_social }}
                      td.text-xs-left {{ props.item.telefone }}
                      td.text-xs-left {{ props.item.cpf_cnpj }}
                      td.text-xs-left {{ props.item.email }}

                      td(width="15%").text-xs-center
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small,  @click="openDialogResultados(props.item)")
                            v-icon visibility
                          span Ver resultados



    v-dialog(v-model="dialogQuestao", width="auto", scrollable, max-width="1000px", persistent, v-if="dialogQuestao")
      v-card
        v-card-title.grey.lighten-2
          v-layout(row, wrap)
            v-flex.md11
              span.headline {{ questaoAux.id ? 'Editar Questão' : 'Cadastrar Questão' }}
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="$validator.pause();  questaoAux = {}; dialogQuestao = false; tab = 0;").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
        v-card-text
          v-tabs(v-model="tab", grow, slider-color="bt-primary").elevation-0
            v-tab(:key="0").primary--text Informações Gerais
            v-tab(:key="1", :disabled="!questaoAux.id").primary--text Respostas

            v-tab-item(:key="0").mt-2
              form(@submit.prevent="salvar('questao')", data-vv-name="questao", v-if="tab == 0")
                v-layout(row, wrap)
                  v-flex.xs12.md12
                    v-layout(row, wrap).mt-4
                      v-flex.xs12.md12.pa-1
                        v-text-field(color="primary", label='Texto', required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", type="text", v-model='questaoAux.texto', autofocus)
                    v-layout(row, wrap).mt-4
                    v-layout(row, wrap)
                      v-flex.xs12.text-xs-right
                        v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

            v-tab-item(:key="1").mt-2.elevation-0
              v-layout(row, wrap)
                v-flex.xs4.md4.text-xs-center.mt-2
                v-flex.xs4.md4.text-xs-center.mt-2
                  v-switch(:false-value="0", :true-value="1", :color="filter.opcao_ativo ? 'success': ''", :label="filter.opcao_ativo ? 'Respostas ativas' : 'Respostas inativas'", v-model="filter.opcao_ativo")
                v-flex.xs4.md4.text-xs-right.mt-2
                  v-btn(color="success", @click.native="openDialogResposta(null)").white--text Cadastrar
              v-layout(row, wrap, v-if="questaoAux.id")
                v-flex.xs12.md12.pa-2
                  v-data-table(:headers="headersRespostas", :items='listOpcoes', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]").elevation-0
                    template(slot='items', slot-scope='props1').elevation-0
                      td(width="5%").text-xs-center {{ props1.item.id }}
                      td.text-xs-left {{ props1.item.texto }}
                      td.text-xs-left {{ props1.item.peso }}
                      td(width="20%").text-xs-center
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, @click="openDialogResposta(props1.item)")
                            v-icon edit
                          span Editar
                        v-tooltip(top)
                          v-btn(slot="activator", flat, icon, fab, dark, color="red", small, @click="changeAtivoOpcao(props1.item)")
                            v-icon {{ props1.item.ativo ? 'delete' : 'restore' }}
                          span {{ props1.item.ativo ? 'Desativar' : 'Ativar' }}


    v-dialog(v-model="dialogResultado", width="auto", scrollable, max-width="1000px", persistent, v-if="dialogResultado")
      v-card
        v-card-title.grey.lighten-2
          v-layout(row, wrap)
            v-flex.md11
              span.headline  Diagnóstico Rápido de {{empresa.nome_responsavel}} - {{empresa.razao_social}}
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="$validator.pause();  resultadoAux = {}; dialogResultado = false").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
        v-card-text
              v-layout(row, wrap)
                v-flex.xs4.md4.text-xs-center.mt-2
                v-flex.xs4.md4.text-xs-center.mt-2
              v-layout(row, wrap)
                v-flex.xs12.md12.pa-2
                  v-data-table(:headers="headersResultadosQuestoes", :items='listResultadosQuestoes', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]").elevation-0
                    template(slot='items', slot-scope='props1').elevation-0
                      td(width="5%").text-xs-center {{ props1.item.id }}
                      td.text-xs-left {{ props1.item.questao_dr.texto }}
                      td.text-xs-left {{ props1.item.opcaoSelecionada.texto }}

    v-dialog(v-model="dialogRespostas", width="auto", scrollable, max-width="500px", persistent, v-if="dialogRespostas")
      v-card
        form(@submit.prevent="salvar('resposta')", data-vv-name="resposta")
          v-card-title.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.md11
                span.headline {{ respostaAux.id ? 'Editar Resposta' : 'Cadastrar Resposta' }}
              v-flex.md1.text-xs-right
                v-tooltip(top)
                  v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogRespostas = false; respostaAux = {}").pa-0.mx-0.my-0.ma-0
                    v-icon close
                  span Fechar
          v-divider
          v-card-text
            v-layout(row, wrap)
              v-flex.xs6.md6.pa-1
                v-text-field(color="primary", label='Texto', required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", type="text", v-model='respostaAux.texto', autofocus)
              v-flex.xs2.md2.pa-1
                v-text-field(color="primary", label='Peso', required, v-validate="'required'", :error-messages="errors.collect('peso')", data-vv-name="peso", type="text", v-model='respostaAux.peso', autofocus)
          v-divider
          v-card-actions.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.xs12.text-xs-right
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

    v-dialog(v-model="dialogCategoria", width="auto", scrollable, max-width="500px", persistent, v-if="questionario.id && dialogCategoria")
      v-card
        form(@submit.prevent="salvar('categoria')", data-vv-name="categoria")
          v-card-title.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.md11
                span.headline {{ categoriaAux && categoriaAux.id ? 'Editar Categoria' : 'Cadastrar Categoria' }}
              v-flex.md1.text-xs-right
                v-tooltip(top)
                  v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogCategoria = false; categoriaAux = {}").pa-0.mx-0.my-0.ma-0
                    v-icon close
                  span Fechar
          v-divider
          v-card-text
              v-layout(row, wrap)
                v-flex.xs12.md12.pa-1
                  v-text-field(color="primary", label='Texto', required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", type="text", v-model='categoriaAux.texto', autofocus)
          v-divider
          v-card-actions.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.xs12.text-xs-right
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

</template>

<script>
import _ from "lodash";
import AccessLock from "@/components/AccessLock";
import CropImage from "@/components/CropImage";
import tinymce from "vue-tinymce-editor";
import pt_BR from "@/assets/pt_BR.js";
import { v4 as uuidv4 } from "uuid";

export default {
    components: {
        CropImage,
        AccessLock,
        tinymce,
    },
    $_veeValidate: {
        validator: "new",
    },
    data: () => ({
        questionario_id: null,
        options: {
            language_url: pt_BR,
        },
        categoriaAux: {},
        linkDiagnosticoRapido: "",
        questaoAux: {},
        respostaAux: {},
        resultados: [],
        imagem: null,
        dialogQuestao: false,
        dialogCategoria: false,
        dialogRespostas: false,
        filter: {
            ativo: 1,
            categoria_ativo: 1,
            questaoAtivo: 1,
            categoria_questao_id: null,
            opcao_ativo: 1,
        },
        loading: false,
        questionario: { moeda: false },
        listTiposQuestionarios: [],
        listLicencas: [],
        categorias: [],
        resultadoAux: [],
        empresa: [],
        questoes: [],
        questao: {},
        categoria: {},
        resposta: {},
        loadingTiposQuestionarios: false,
        loadingLicencas: false,
        loadingCategorias: false,
        dialogResultado: false,
        listResultadosQuestoes: [],
        headers: [
            { text: "#", align: "center", value: "id" },
            { text: "Texto", align: "left", value: "texto" },
            { text: "Ações", align: "center", sortable: false },
        ],
        headersCategorias: [
            { text: "#", align: "center", value: "id" },
            { text: "Texto", align: "left", value: "texto" },
            { text: "Ações", align: "center", sortable: false },
        ],
        headersRespostas: [
            { text: "#", align: "center", value: "id" },
            { text: "Texto", align: "left", value: "texto" },
            { text: "Peso", align: "left", value: "peso" },
            { text: "Ações", align: "center", sortable: false },
        ],
        headersResultados: [
            { text: "#", align: "center", value: "id" },
            { text: "Nome do responsável", align: "left", value: "texto" },
            { text: "Razão social", align: "left", value: "texto" },
            { text: "Telefone", align: "left", value: "texto" },
            { text: "CPF/CNPJ", align: "left", value: "texto" },
            { text: "Email", align: "left", value: "texto" },
            { text: "Ações", align: "center", sortable: false },
        ],
        headersResultadosQuestoes: [
            { text: "#", align: "center", value: "id" },
            { text: "Questão", align: "left", value: "texto" },
            { text: "Resposta", align: "left", value: "texto" },
        ],
        tab: 0,
    }),
    computed: {
        list() {
            const vm = this;
            let questoes = _.cloneDeep(vm.questoes);
            return questoes;
        },
        listOpcoes() {
            const vm = this;
            let resultados = _.cloneDeep(vm.questaoAux.respostas);
            return _.filter(resultados, { ativo: vm.filter.opcao_ativo });
        },
        listQuestoes() {
            const vm = this;
            let questoes = _.cloneDeep(vm.questoes);
            return questoes;
        },
        listResultados() {
            const vm = this;
            let resultados = _.cloneDeep(vm.resultados);
            return resultados;
        },
    },
    async mounted() {
        const vm = this;
        if (vm.$route.params.id) {
            await vm.getQuestionario(vm.$route.params);
            await vm.getResultados(vm.$route.params);
            vm.questionario_id = vm.$route.params.id;
            console.log(window.location.origin);
            vm.linkDiagnosticoRapido = `${window.location.origin}/diagnostico-rapido/questionario/${vm.questionario.uuid}`;
        }
    },
    methods: {
        copyTestingCode() {
            let testingCodeToCopy = document.querySelector(
                "#linkDiagnosticoRapido",
            );
            testingCodeToCopy.setAttribute("type", "text");
            testingCodeToCopy.select();
            try {
                var successful = document.execCommand("copy");
                var msg = successful
                    ? "Link copiado com sucesso"
                    : "Erro ao copiar o link";
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: msg,
                });
            } catch (err) {
                window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Erro ao copiar o link",
                });
            }

            /* unselect the range */
            // testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges();
        },
        async save(questionario) {
            let user = this.$store.getters.user;
            questionario.uuid = uuidv4();
            const vm = this;
            try {
                const response = await vm.$axios.post(
                    "/questionario-dr",
                    questionario,
                );
                questionario.id = response.data.id;
                // console.log(uuidv4(), "uuid do quest");
                setTimeout(() => {
                    window.location.href = `/diagnostico-rapido/cadastro/${response.data.id}`;
                }, 200);
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Diagnóstico Rápido salvo com sucesso!",
                });
            } catch (error) {
                console.log(error, "erro");
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar o diagnóstico rápido. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async update(questionario) {
            const vm = this;
            try {
                const response = await vm.$axios.put(
                    `/questionario-dr/${questionario.id}`,
                    questionario,
                );
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Diagnóstico Rápido salvo com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar o questionário. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async salvar(scope) {
            const vm = this;
            try {
                vm.loading = true;
                const result = await vm.$validator.validateAll(scope);
                if (!result) throw "Preencha todos os campos corretamente!";
                if (scope === "questionario") {
                    vm[vm.questionario_id ? "update" : "save"](vm.questionario);
                } else if (scope === "questao") {
                    vm[vm.questaoAux.id ? "updateQuestao" : "saveQuestao"](
                        vm.questaoAux,
                    );
                } else if (scope === "resposta") {
                    vm[vm.respostaAux.id ? "updateResposta" : "saveResposta"](
                        vm.respostaAux,
                    );
                }
                vm.$validator.reset();
            } catch (error) {
                window.getApp.$emit("APP_ALERT", { color: "red", text: error });
                vm.loading = false;
            }
        },
        async saveQuestao(questao) {
            const vm = this;
            try {
                vm.loading = true;
                questao.questionario_id = vm.questionario.id;
                const response = await vm.$axios.post("/questao-dr", questao);
                vm.questaoAux = response.data;
                if (response.data && response.data.id) {
                    let questaoData = {
                        idQuestaoQuestionario: response.data.questionario_df_id,
                        id: response.data.id,
                        texto: response.data.texto,
                        ativo: response.data.ativo,
                    };
                    vm.questoes.push(questaoData);
                    vm.tab = 1;
                    vm.loading = false;
                    window.getApp.$emit("APP_ALERT", {
                        color: "success",
                        text: "Questão salva com sucesso!",
                    });
                } else {
                    vm.loading = false;
                    throw false;
                }
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async updateQuestao(questaoAux) {
            const vm = this;
            try {
                vm.filter.opcao_ativo = 0;
                const response = await vm.$axios.put(
                    `/questao-dr/${questaoAux.id}`,
                    questaoAux,
                );
                const index = _.findIndex(vm.questoes, { id: questaoAux.id });
                const questoes = _.reject(vm.questoes, { id: questaoAux.id });
                vm.questoes = [
                    ...questoes.slice(0, index),
                    response.data,
                    ...questoes.slice(index),
                ];
                vm.loading = false;
                vm.filter.opcao_ativo = 1;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Questão salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar a questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async saveResposta(resposta) {
            const vm = this;
            try {
                vm.filter.opcao_ativo = 0;
                resposta.questao_dr_id = vm.questaoAux.id;
                const response = await vm.$axios.post(
                    "/opcoes-questao-dr",
                    resposta,
                );
                if (vm.questaoAux.respostas) {
                    vm.questaoAux.respostas.push(response.data);
                } else {
                    vm.questaoAux.respostas = [];
                    vm.questaoAux.respostas.push(response.data);
                }
                vm.dialogRespostas = false;
                vm.respostaAux = {};
                vm.filter.opcao_ativo = 1;
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Resposta da questão salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a resposta da questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async updateResposta(respostaAux) {
            const vm = this;
            try {
                const response = await vm.$axios.put(
                    `/opcoes-questao-dr/${respostaAux.id}`,
                    respostaAux,
                );
                const index = _.findIndex(vm.categorias, {
                    id: respostaAux.id,
                });
                const respostas = _.reject(vm.questaoAux.respostas, {
                    id: respostaAux.id,
                });
                vm.questaoAux.respostas = [
                    ...respostas.slice(0, index),
                    response.data,
                    ...respostas.slice(index),
                ];
                vm.dialogRespostas = false;
                vm.respostaAux = {};
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Resposta salva com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar a resposta. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async getQuestionario(params) {
            let vm = this;
            try {
                const response = await vm.$axios.get(
                    `/questionario-dr/${params.id}`,
                    { params: { fields: "questoes_questionario_dr" } },
                );
                vm.questionario = response.data || {};
                _.map(vm.questionario.questoes_questionario_dr, (obj) => {
                    vm.questoes.push({
                        idQuestaoQuestionario: obj.id,
                        id: obj.questao_dr.id,
                        texto: obj.questao_dr.texto,
                        ativo: obj.ativo,
                    });
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async getResultados(params) {
            let vm = this;
            try {
                const response = await vm.$axios.get(
                    `/questionario-dr/${params.id}`,
                    { params: { fields: "diagnostico_rapido" } },
                );
                vm.resultados =
                    response &&
                    response.data &&
                    response.data.diagnostico_rapido
                        ? response.data.diagnostico_rapido
                        : {};
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async openDialogQuestao(questao) {
            let vm = this;
            try {
                if (questao && questao.id) {
                    vm.questaoAux = _.cloneDeep(questao);
                    const response = await vm.$axios.get(
                        `/questao-dr/${vm.questaoAux.id}`,
                        { params: { fields: "opcoes_questao_dr" } },
                    );
                    vm.questaoAux = response.data;
                    vm.questaoAux.respostas = response.data.opcoes_questao_dr;
                    vm.imagem = response.data.dica_imagem;
                    delete vm.questaoAux.opcoes_questao;
                }
                vm.dialogQuestao = true;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async openDialogResultados(resultado) {
            let vm = this;
            try {
                vm.listResultadosQuestoes = [];
                vm.empresa = resultado;
                if (resultado && resultado.id) {
                    _.map(resultado.resultado_valor_dr, (obj) => {
                        obj.opcaoSelecionada = _.find(
                            obj.questao_dr.opcoes_questao_dr,
                            { id: obj.opcao_questao_dr_id },
                        );
                        vm.listResultadosQuestoes.push(obj);
                    });
                }
                vm.dialogResultado = true;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async openDialogResposta(resposta) {
            let vm = this;
            try {
                if (resposta && resposta.id) {
                    vm.respostaAux = _.cloneDeep(resposta);
                    const response = await vm.$axios.get(
                        `/opcoes-questao-dr/${vm.respostaAux.id}`,
                    );
                    vm.respostaAux = response.data;
                }
                vm.dialogRespostas = true;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async changeAtivoOpcao(opcao) {
            const vm = this;
            try {
                const obj = _.cloneDeep(opcao);
                obj.ativo = obj.ativo ? 0 : 1;
                const response = await vm.$axios.put(
                    `/opcoes-questao-dr/${obj.id}`,
                    obj,
                );
                opcao.ativo = response.data.ativo;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a categoria. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async deleteQuestao(questao) {
            const vm = this;
            try {
                const response = await vm.$axios.delete(
                    `/questao-dr/${questao.id}`,
                );
                if (response.data) {
                    vm.questoes = _.reject(vm.questoes, { id: questao.id });
                    window.getApp.$emit("APP_ALERT", {
                        color: "warning",
                        text: "Questão excluída  com sucesso!",
                    });
                } else {
                    throw false;
                }
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao exluir a questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        customFilter(item, queryText, itemText) {
            const hasValue = (val) => (val != null ? val : "");
            const text = hasValue(item.nome || item.texto);
            const query = hasValue(queryText);
            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },
    },
};
</script>

<style scoped></style>
